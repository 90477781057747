import React from 'react';
import styled from 'styled-components';
import IconContainer from './IconContainer';

const ContactInfoCard = ({icon, title, children}) => {
  return (
    <StyledContactInfoCard>
      <div className="card-head">
        <IconContainer icon={icon} bgColor='#111' containerSize='2.5em' />
        <h2>{title}</h2>
      </div>
      <div className="card-content">
        {children}
      </div>
    </StyledContactInfoCard>
  );
};

const StyledContactInfoCard = styled.div`
  text-align: center;
  border-radius: 20px;
  overflow: hidden;

  .card-head {
    background-color: var(--primary-color);
    padding: 0.75rem;

    .icon-container {
      margin: 0 auto 0.5rem auto;
    }
  }

  .card-content {
    flex-grow: 1;
    background-color: #222;
    padding: 2rem;
    /* height: 100%; */
    line-height: 1.5;

    ul {
      list-style: none;
    }
  }
`

export default ContactInfoCard;
