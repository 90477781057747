import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { FaMap, FaInfo, FaMapPin } from 'react-icons/fa'

import Seo from '../components/SEO'
import PageBanner from '../components/PageBanner';
import ContactInfoCard from '../components/ContactInfoCard';
import SectionDivider from '../components/SectionDivider';

function encode(data) {
  return Object.keys(data)
    .map(
      (key) =>
        encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
    )
    .join("&");
}

const ContactPage = ({ data }) => {
  const bannerImage = data.file.childImageSharp

  const ContactSchema = Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
    subject: Yup.string(),
    message: Yup.string().required('Required'),
  })

  return (
    <>
    <Seo title='Contact' image={`https://www.sarpl.co.za${data.file.publicURL}`} />
      <StyledContactPage>
        <PageBanner title='Contact Us' image={bannerImage} imagePosition='center 27.5%' />

        <div className="page-container">
          <div className="site-container">
            <h2>Contact Form</h2>
            <Formik
              initialValues={{
                firstName: '',
                lastName: '',
                email: '',
                subject: '',
                message: ''
              }}
              validationSchema={ContactSchema}
              onSubmit={(values, { resetForm }) => {
                fetch('/', {
                  method: "POST",
                  headers: { "Content-Type": "application/x-www-form-urlencoded" },
                  body: encode({
                    "form-name": "Contact",
                    ...values
                  })
                })
                  .then(() => {
                    resetForm()
                    alert('Thank you for submitting the contact form, we\'ll get back to you as soon as possible. ')
                  })
                  .catch(() => {
                    alert('An error occured, check the console for details. ')
                  })
              }}
            >
              {() => ( // { errors, touched }
                <Form name='Contact' netlify>
                  <input type="hidden" name="form-name" value="Contact" />
                  <span>
                    <label htmlFor="firstName">First Name</label>
                    <Field name='firstName' />
                    <ErrorMessage name='firstName' />
                  </span>
                  <span>
                    <label htmlFor="lastName">Last Name</label>
                    <Field name='lastName' />
                    <ErrorMessage name='lastName' />
                  </span>
                  <span>
                    <label htmlFor="email">Email</label>
                    <Field type='email' name='email' />
                    <ErrorMessage name='email' />
                  </span>
                  <span>
                    <label htmlFor="subject">Subject</label>
                    <Field name='subject' />
                    <ErrorMessage name='subject' />
                  </span>
                  <span className='c-span-full'>
                    <label htmlFor="message">Message</label>
                    <Field name='message' as='textarea' />
                    <ErrorMessage name='message' />
                  </span>

                  <span className="c-span-full">
                    <button type="submit">Submit</button>
                  </span>
                </Form>
              )}
            </Formik>

            <SectionDivider />

            <section className="cards">
              <ContactInfoCard icon={<FaMap />} title='Regional Liaisons'>
                <h3>Gauteng</h3>
                <p>Paul Grobler: <a href='mailto:gauteng@sarpl.co.za'>gauteng@sarpl.co.za</a></p>
                <h3>Eastern Cape</h3>
                <p>Glen Gouws: <a href='ec@sarpl.co.za'>ec@sarpl.co.za</a></p>
              </ContactInfoCard>
              <ContactInfoCard icon={<FaInfo />} title='SARPL'>
                <ul>
                  <li>Tel: <a href='tel:+27870940193'>087 094 0193</a></li>
                  <li>Mobile: <a href='tel:+27834499819'>083 449 9819</a></li>
                  <li>E-Mail: <a href='mailto:national@sarpl.co.za'>national@sarpl.co.za</a></li>
                </ul>
              </ContactInfoCard>
              <ContactInfoCard icon={<FaMapPin />} title='Office'>
                <a href="https://www.google.com/maps/dir/?api=1&destination=IT+Genie+Pty+Ltd">7 Henry Road, Northvale AH, Muldersdrift</a>
              </ContactInfoCard>
            </section>
          </div>
        </div>
      </StyledContactPage>
    </>
  );
};

export const query = graphql`
  query ContactPageQuery {
    file(name: {eq: "contact-banner"}) {
      childImageSharp {
        gatsbyImageData
      }
      publicURL
    }
  }
`

const StyledContactPage = styled.main`
  /* h1 {
    font-size: 2.5rem;
    text-align: center;
    margin: 2rem;
  } */
  .page-container {
    padding: 1rem;
  }
  
  .site-container {
    padding: 3rem 0;

    & > h2 {
      font-size: 2.25rem;
      text-align: center;
      /* margin: 5rem 0 3rem 0; */
      text-transform: uppercase;
    }
  }

  section.cards {
    display: flex;
    flex-flow: row wrap;
    gap: 2rem;

    & > * {
      flex: 1 1 auto;
      flex-basis: 300px;
      display: flex;
      flex-direction: column;
    }
  }

  form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.5rem;
    background-color: #222;
    margin: 2rem 0;
    padding: 2rem;
    border-radius: 20px;

    label {
      color: #ccc;
      display: inline-block;
      margin-bottom: 0.4rem;
    }
    
    input, textarea {
      background-color: #333;
      color: #fafafa;
      border: 0;
      border-radius: 5px;
      font-size: 1.4rem;
      padding: 0.3em;
      margin-bottom: 0.2rem;
      width: 100%;

      &:focus {
        outline-color: var(--primary-color);
        outline-style: solid;
      }
    }

    textarea {
      font-family: inherit;
      min-height: 100px;
    }

    .c-span-full {
      grid-column: 1 / -1;
    }

    button {
      display: block;
      background-color: var(--primary-color);
      color: #fafafa;
      width: fit-content;
      margin: auto;
      padding: 0.5em 1.6em;
      border: solid 2px var(--primary-color);
      border-radius: 50px;
      font-size: 1.1rem;
      text-transform: uppercase;
      letter-spacing: 1px;
      transition: background-color 0.5s;
      cursor: pointer;

      &:hover {
        background-color: #111;
      }
    }
  }

  @media (max-width: 768px) {
    .site-container {
      padding: 2rem 0;
    }

    section.cards {
      & > *:nth-child(2) {
        order: -1;
      }
    }
  }

  @media (max-width: 480px) {
    form {
      grid-template-columns: 1fr;
      grid-gap: 1rem;
      padding: 1rem;
    }
  }
`

export default ContactPage;
